import NeoService from 'shared_js/api/neo';
import ConsentService from 'shared_js/api/consents';
import {
  CONSENT_MANAGEMENT_TOGGLE,
  CONSENT_MANAGEMENT_CONFIG,
  USER_CONSENT_NOT_FOUND_CODE,
} from 'shared_js/constants/consents';

export default {
  namespaced: true,
  state: () => ({
    nonConsentedProductIds: [],
    latestConsents: [],
    remindEvery: 0,
    showSnackbar: false,
    snackbar: {
      message: '',
      isError: false,
    },
    recordUserConsents: {
      isLoading: false,
      isError: false,
    },
  }),
  getters: {
    nonConsentedProductIds: state => state.nonConsentedProductIds,
    latestConsents: state => state.latestConsents,
    remindEvery: state => state.remindEvery,
    showSnackbar: state => state.showSnackbar,
    snackbar: state => state.snackbar,
    recordUserConsents: state => state.recordUserConsents,
  },
  mutations: {
    setNonConsentedProductIds(state, ids) {
      state.nonConsentedProductIds = ids;
    },
    setLatestConsents(state, consents) {
      state.latestConsents = consents;
    },
    setRemindEvery(state, day) {
      state.remindEvery = Number(day);
    },
    setShowSnackbar(state, { showSnackbar }) {
      state.showSnackbar = showSnackbar;
    },
    setSnackbar(state, { message, isError }) {
      if (!state.snackbar) {
        state.snackbar = {};
      }
      state.snackbar.message = message;
      state.snackbar.isError = isError;
    },
    setRecordUserConsents(state, { isLoading, isError }) {
      state.recordUserConsents = { isLoading, isError };
    },
  },
  actions: {
    async fetchToggles(_, platform) {
      try {
        const toggles = await NeoService.retrieveToggles({ id: [CONSENT_MANAGEMENT_TOGGLE], platform });
        return toggles.data[0].active;
      } catch (error) {
        throw new Error('Failed to retrieve toggles.');
      }
    },
    async fetchConfigs(_, platform) {
      try {
        const configs = await NeoService.retrieveConfigs({ id: [CONSENT_MANAGEMENT_CONFIG], platform });
        return configs.data[0].data;
      } catch (error) {
        throw new Error('Failed to retrieve configs.');
      }
    },
    async fetchUserConsents({ dispatch, commit }, platform = 'bl_mobile_web') {
      try {
        // Check if consent management toggle is active
        const isActive = await dispatch('fetchToggles', platform);
        if (!isActive) return;

        // Retrieve product IDs from configs
        const configData = await dispatch('fetchConfigs', platform);
        const productIds = Object.values(configData.product_ids);
        if (!productIds.length) return;

        // Check user consents for each product
        const userConsentsResults = await Promise.allSettled(
          productIds.map(productId => ConsentService.checkUserConsents(productId)),
        );

        // Determine non-consented product IDs
        const nonConsentedProductIds = productIds.filter((id, index) => {
          const response = userConsentsResults[index];
          const errorCode = response.reason?.response?.data?.errors?.[0]?.code;
          // handle when request rejected
          const rejectedRequest = response.status === 'rejected' && errorCode === USER_CONSENT_NOT_FOUND_CODE;
          // handle when request fulfilled, but the consented is false
          const fulfilledRequest = response.status === 'fulfilled' && !response.value.data.consented;
          return rejectedRequest || fulfilledRequest;
        });

        // Fetch latest consents for non-consented product IDs
        const latestConsentsResults = await Promise.allSettled(
          nonConsentedProductIds.map(productId => ConsentService.fetchLatestConsents(productId)),
        );

        // Collect latest consents
        const latestConsents = latestConsentsResults
          .filter(result => result.status === 'fulfilled')
          .map((result, index) => ({
            productId: nonConsentedProductIds[index],
            ...result.value.data,
          }));

        commit('setNonConsentedProductIds', nonConsentedProductIds);
        commit('setRemindEvery', configData.remind_every);
        commit('setLatestConsents', latestConsents);
      } catch (error) {
        dispatch('showSnackbarMessage', { message: 'Terjadi kesalahan.', isError: true });
      }
    },
    async recordUserConsents({ state, commit, dispatch }) {
      let isError = false;
      try {
        commit('setRecordUserConsents', {
          isLoading: true,
          isError,
        });
        await Promise.all(
          state.latestConsents.map(latestConsent =>
            ConsentService.recordUserConsents({
              agreement_id: latestConsent.id,
              consented: true,
            }),
          ),
        );
        commit('setNonConsentedProductIds', []);
        commit('setLatestConsents', []);
      } catch (error) {
        isError = true;
        dispatch('showSnackbarMessage', { message: 'Terjadi kesalahan.', isError: true });
      } finally {
        commit('setRecordUserConsents', {
          isLoading: false,
          isError,
        });
      }
    },
    showSnackbarMessage({ commit }, { message, isError }) {
      commit('setSnackbar', { message, isError });
      commit('setShowSnackbar', { showSnackbar: true });
    },
  },
};
