<template>
  <div class="sheet-consent-detail">
    <BlSheetMv
      :active.sync="openSheet"
      :title="title"
      :is-draggable="false"
    >
      <div slot="iconLeftNavBar">
        <BlIconAv
          name="ico_cross_minor"
          color="#141414"
        />
      </div>
      <div slot="title">
        <BlTextAv variation="subheading-18">
          {{ title }}
        </BlTextAv>
      </div>
      <div
        slot="content"
        class="content-wrapper"
      >
        <BlTextAv
          variation="body-14"
          v-html="content"
        />
      </div>
    </BlSheetMv>
  </div>
</template>

<script>
import BlTextAv from '@bukalapak/bazaar-mweb/dist/components/BlTextAv'
import BlSheetMv from '@bukalapak/bazaar-mweb/dist/components/BlSheetMv';
import { BlIconAv } from '@bukalapak/bazaar-visual';

export default {
  name: 'SheetConsentDetail',
  components: {
    BlTextAv,
    BlSheetMv,
    BlIconAv,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openSheet: false,
    };
  },
  watch: {
    active: {
      immediate: true,
      handler(val) {
        this.openSheet = val;
      },
    },

    openSheet(val) {
      this.$emit('update:active', val);
    },
  },
}
</script>

<style scoped>
.content-wrapper {
  padding: 16px;
}
</style>
