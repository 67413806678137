import Logger from 'lib/logger';
import LoggerPlugin from 'lib/logger/plugin';
import consents from 'shared_js/stores/consents';
import App from './App.vue';

const logger = new Logger();

function createApp({ Vue, Vuex }) {
  Vue.use(LoggerPlugin, { logger });
  Vue.use(Vuex);

  const store = new Vuex.Store({ modules: { consents } });

  const app = new Vue({
    store,
    render: h => h(App),
  });

  return { app, store };
}

export default createApp;
