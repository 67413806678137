<template>
  <div class="sheet-consent">
    <BlSheetMv
      :active.sync="openSheet"
      auto-height
    >
      <div slot="iconLeftNavBar">
        <BlIconAv
          name="ico_cross_minor"
          color="#141414"
        />
      </div>
      <div slot="title" />
      <div
        slot="content"
        class="content-wrapper"
      >
        <BlImageAv
          :src="illustUrl"
          width="200px"
          height="124px"
          alt="instruction-illustration"
          unoptimized
        />
        <div class="content-title">
          <BlTextAv variation="subheading-20">
            Permisi, mau info
          </BlTextAv>
        </div>
        <div class="content-body">
          <BlTextAv variation="body-14">
            Ada pembaruan
            <template v-if="latestConsents && latestConsents.length">
              <span
                v-for="(consent, index) in latestConsents"
                :key="index"
              >
                <BlLinkAv @click.native.prevent="onClickConsentDetail(consent)">{{ consent.name }}</BlLinkAv>
                <span v-if="index < latestConsents.length - 2">, </span>
                <span v-if="index === latestConsents.length - 2">dan </span>
              </span>
            </template>
            nih. Dengan klik setuju, kamu sudah membaca dan menyetujui ketentuan baru tersebut.
          </BlTextAv>
        </div>
        <div class="content-action">
          <BlButtonAv
            fullwidth
            :is-loading="isLoading"
            @click="onClickAgree"
          >
            Setuju
          </BlButtonAv>
        </div>
      </div>
    </BlSheetMv>
  </div>
</template>

<script>
import BlImageAv from '@bukalapak/bazaar-visual-v1/dist/components/BlImageAv';
import BlTextAv from '@bukalapak/bazaar-mweb/dist/components/BlTextAv'
import BlButtonAv from '@bukalapak/bazaar-mweb/dist/components/BlButtonAv'
import BlSheetMv from '@bukalapak/bazaar-mweb/dist/components/BlSheetMv';
import BlLinkAv from '@bukalapak/bazaar-mweb/dist/components/BlLinkAv';
import { BlIconAv } from '@bukalapak/bazaar-visual';

export default {
  name: 'SheetConsent',
  components: {
    BlTextAv,
    BlSheetMv,
    BlButtonAv,
    BlIconAv,
    BlImageAv,
    BlLinkAv
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    latestConsents: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      openSheet: false,
      illustUrl: 'https://s0.bukalapak.com/illustration/original/img_medium_fun_new_update_feature_bukalapak.png',
    };
  },
  watch: {
    active: {
      immediate: true,
      handler(val) {
        this.openSheet = val;
      },
    },
    openSheet(val) {
      this.$emit('update:active', val);
    },
  },
  methods: {
    onClickConsentDetail(consent) {
      this.$emit('onClickConsentDetail', consent);
    },
    onClickAgree() {
      this.$emit('onClickAgree');
    },
  }
}
</script>

<style scoped>
.content-wrapper {
  text-align: center;
}
.content-title {
  padding-top: 24px;
  padding-bottom: 8px;
}
.content-body {
  margin: 0 32px;
  line-height: 20px;
}
.content-action {
  padding: 16px 40px;
  margin-top: 16px;
}
.link {
  color:  #2B4AC7 !important;
}
</style>
