export default {
  checkUserConsents(productId) {
    return this.get(`/_exclusive/users/consents`, 'user', {
      params: {
        product_id: productId,
      },
    });
  },
  fetchLatestConsents(productId) {
    return this.get(`/_exclusive/consents/products/${productId}/agreements/latest`, 'public');
  },
  recordUserConsents(data) {
    return this.put(`/_exclusive/users/consents`, 'user', { data });
  },
};
