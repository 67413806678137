import Cookies from 'js-cookie';
import { mapGetters } from 'vuex';

const COOKIE_NAME = 'lastconsent';

// this mixins will be used by mweb and dweb homepage
export default {
  data() {
    return {
      sheetConsentActive: false,
      sheetConsentDetailActive: false,
      sheetConsentDetailTitle: '',
      sheetConsentDetailContent: '',
      currentUserId: window?.USER?.id || '',
    };
  },
  computed: {
    ...mapGetters('consents', [
      'nonConsentedProductIds',
      'latestConsents',
      'remindEvery',
      'snackbar',
      'recordUserConsents',
    ]),
    showSnackbar: {
      get() {
        return this.$store.state.consents.showSnackbar;
      },
      set(val) {
        this.$store.commit('consents/setShowSnackbar', { showSnackbar: val });
      },
    },
  },
  watch: {
    latestConsents(val) {
      this.sheetConsentActive = val.length > 0; // Set active based on the latest consents
    },
    sheetConsentActive(val) {
      if (!val) {
        Cookies.set(COOKIE_NAME, this.currentUserId, { expires: this.remindEvery });
      }
    },
  },
  mounted() {
    if (window && window.USER.id) {
      // Check logged in user
      const userIdCookie = Cookies.get(COOKIE_NAME);

      if (userIdCookie === String(this.currentUserId)) return;

      Cookies.remove(COOKIE_NAME);

      this.$store.dispatch('consents/fetchUserConsents', this.platform); // Fetch user consents on mount
    }
  },
  methods: {
    onClickConsentDetail(consent) {
      this.sheetConsentDetailTitle = consent.name;
      this.sheetConsentDetailContent = consent.agreement;
      this.sheetConsentDetailActive = true; // Activate consent detail
    },
    async onClickAgree() {
      if (this.recordUserConsents.isLoading) return;
      await this.$store.dispatch('consents/recordUserConsents');
    },
  },
};
