<template>
  <div class="consent-popup-mweb">
    <SheetConsent
      :active.sync="sheetConsentActive"
      :latest-consents="latestConsents"
      :is-loading="recordUserConsents.isLoading"
      @onClickConsentDetail="onClickConsentDetail"
      @onClickAgree="onClickAgree"
    />
    <SheetConsentDetail
      :active.sync="sheetConsentDetailActive"
      :title="sheetConsentDetailTitle"
      :content="sheetConsentDetailContent"
    />
    <BlSnackbarMv
      :active.sync="showSnackbar"
      :is-error="snackbar.isError"
      :message="snackbar.message"
    />
  </div>
</template>

<script>
import consentPopupMixins from 'shared_js/mixins/consent-popup-mixins';
import BlSnackbarMv from '@bukalapak/bazaar-mweb/dist/components/BlSnackbarMv';
import SheetConsent from './components/SheetConsent.vue';
import SheetConsentDetail from './components/SheetConsentDetail.vue';

export default {
  name: 'App',
  components: {
    SheetConsent,
    SheetConsentDetail,
    BlSnackbarMv
  },
  mixins: [consentPopupMixins],
  data () {
    return {
      platform: 'bl_mobile_web',
    }
  },
};
</script>

